<template>
	<v-card>
		<v-card-title>
			<v-row>
				<v-col cols="6">Device Link History</v-col>
			</v-row>
		</v-card-title>

		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<v-data-table ref="dataTable" :headers="headers" :items="deviceHistory" :loading="status.loading" class="elevation-1">
						<template v-slot:item.Action="{ item }">
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-icon v-on="on">
										{{ item.Action === "link" ? "link" : "link_off" }}
									</v-icon>
								</template>
								<span>{{ item.Action === "link" ? "Linked" : "Not linked" }}</span>
							</v-tooltip>
						</template>
						<template v-slot:item.ActionDate="{ item }">
							{{ formatDate(item.ActionDate) }}
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "OrganisationsDeviceLinkHistory",

	props: {
		orgName: {
			type: String,
			default: ""
		},
		orgCode: {
			type: String,
			default: ""
		}
	},

	computed: {
		selectedDevice: function() {
			return this.$store.state.devices.selectedDevice;
		},
		deviceHistory: function() {
			return this.$store.state.devices.deviceHistory;
		},
		status: function() {
			return this.$store.state.devices.status;
		},
		pagination: function() {
			return this.$store.state.organisations.pagination;
		}
	},

	data: function() {
		return {
			headers: [
				{ text: "Action", value: "Action", align: "center", sortable: false },
				{ text: "User", value: "UserName", align: "center", sortable: false },
				{ text: "Organisation", value: "OrgName", align: "center", sortable: false },
				{ text: "Organisation Code", value: "ShopCode", align: "center", sortable: false },
				{ text: "Date", value: "ActionDate", align: "center" }
			]
		};
	},

	created: function() {
		if (this.selectedDevice == 0) {
			this.$store.dispatch("organisations/paginate", { ...this.pagination, itemsPerPage: -1, page: 1 });
			// return to profile
			this.$router.push({ name: `organisation-devices` });
		}
	},

	methods: {
		formatDate(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		}
	}
});
</script>

<style></style>
